.container-table::-webkit-scrollbar {
  width: 0px;
}

.card-user-list {
  height: calc(100vh - 140px);
  flex-direction: column;
}

.wrapper-pagination-number {
  width: fit-content;
  overflow-x: auto;
  scroll-behavior: smooth;
  display: flex;
  align-items: center;
}

.wrapper-pagination-number::-webkit-scrollbar {
  height: 0px;
}

.list-pagination-number {
  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;
  @media screen and (max-width: 767px) {
    gap: 5px;
  }
}

.mobile-table {
  @media screen and (max-width: 767px) {
    tr {
      border-bottom: 35px solid #f8f8f8;
      td:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
      }
    }
  }
}

.date-range-picker-custom {
  .rdrMonth {
    width: auto;
  }
}
